<template lang="">
  <v-card>
      <s-crud
          :config="config"
          title="Nivel Educativo"
          :filter="filter"
          add
          edit
          remove
          @save="save($event)"
      >
          <template v-slot:filter>
              <v-container>
                  <!-- <v-row>                        
                      <v-col>
                          <s-text
                              label="Codigo"
                              v-model="filter.ScfCode"
                          ></s-text>
                      </v-col>
                  </v-row> -->
              </v-container>
          </template>
          <template v-slot="props">
              <v-container>
                  <v-row >
                       <v-col class="s-col-form" sm="3" md="3">
                          <s-text                                
                              label="Descripcion*" 
                              v-model="props.item.LveDescription"/>
                      </v-col>
                      <v-col class="s-col-form" sm="3" md="3">
                          <s-text
                              label="IDScire*" 
                              v-model="props.item.IDScire"/>
                      </v-col> 
                  </v-row>
              </v-container>
          </template>
      </s-crud>
  </v-card>
</template>

<script>

  import SText from '../../components/Utils/SText.vue'
  import ServiceEducationLevel from "./../../services/HumanResource/HmnEducationalLevel";

  export default {
    components: {
      SText,
    },
    data() {
      return {
          filter:{},
          config: {
            service: ServiceEducationLevel,
            model: {
                LveID : "ID"
            },
            headers: [
                { text: "ID", value: "LveID" },
                { text: "Descripcion", value: "LveDescription" },
                { text: "IDScire", value: "IDScire" }
            ]
          }
      }
    },
    methods:{
      save(item){
            
        console.log(item);
        if(item.LveDescription.length <= 0){
            this.$fun.alert("El campo Descripción es Obligatorio","warning");
            return;
        }
        if(item.IDScire.length <= 0){
            this.$fun.alert("El campo IDScire es Obligatorio","warning");
            return;
        }

        item.save();
      }        
    }
  }
</script>
